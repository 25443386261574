import React from 'react';

interface NewsletterModalProps {
  sourcePage?: string;
}

const NewsletterModal = (props: NewsletterModalProps) => {
  return (
    <a
      className="bg-parityPink font-title text-lg md:text-xl text-white font-semibold opacity-90 py-1 mb-4 focus:outline-none hover:opacity-80 transition-transform transform hover:-translate-y-0.5 focus:bg-buttonRed sm:w-1/2 sm:ml-4 no-underline flex justify-between items-center"
      href="https://www.kinera.network/#newsletter"
      target="_blank"
      rel="noreferrer"
    >
      <div className="bg-black bg-opacity-10 py-1 px-3">
        <svg
          className="fill-current text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-2 -5 24 24"
          width="24"
          height="24"
          preserveAspectRatio="xMinYMin"
        >
          <path d="M18.572.083L10.676 7.12a1 1 0 0 1-1.331 0L1.416.087A2 2 0 0 1 2 0h16a2 2 0 0 1 .572.083zm1.356 1.385c.047.17.072.348.072.532v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2c0-.185.025-.364.072-.534l7.942 7.148a3 3 0 0 0 3.992 0l7.922-7.146z"></path>
        </svg>
      </div>
      <div className="w-full text-center">Subscribe to the newsletter</div>
    </a>
  );
};

export default NewsletterModal;